<template lang="pug">
CardComponent.flex-grow
  paragraphCard Plan de prueba
  p.text-sm.text-center.font-medium.pt-1.text-blue-400 {{ trialPeriod }}
  paragraphCard.price {{ trialPrice }}*
  buttonCard Probar
CardComponent.flex-grow
  paragraphCard Plan mensual
  p.text-sm.text-center.font-medium.pt-1.text-blue-400 Suscripción mensual
  paragraphCard.price {{ subscriptionPrice }}
  buttonCard Comprar
</template>

<script>
import ParseCash from "@/composables/parse-cash";
const { parseCash } = ParseCash();
import CardComponent from "./components/card.vue";
import paragraphCard from "./components/card-p.vue";
import buttonCard from "./components/card-button.vue";

export default {
  name: "card-subscription",
  components: {
    CardComponent,
    paragraphCard,
    buttonCard,
  },
  props: {
    item: {
      type: Object,
      default: null,
    },
  },
  computed: {
    downloads() {
      return this.item.downloads_sum;
    },
    price() {
      return parseCash(
        this.item.price_purchase,
        this.item.product_currency,
        this.item.product_sign
      );
    },
    trialPrice() {
      return parseCash(
        this.item.price_trial,
        this.item.product_currency,
        this.item.product_sign
      );
    },
    subscriptionPrice() {
      return parseCash(
        this.item.price_subscription,
        this.item.product_currency,
        this.item.product_sign
      );
    },
    trialPeriod() {
      const isInHours = this.item.trial_period == 1;
      if (isInHours) return "24 horas";
      return this.item.trial_period + " Días";
    },
  },
};
</script>

<style lang="scss" scoped></style>
