<template lang="pug">
.flex.flex-col
  .container
    .max-w-4xl.mx-auto
      .prices-component
        .col-1
          h1 Accede a todas las ventajas
          listComponent(:listText="listText")
  .bg-blue-50.pb-16.pt-8
    .container
      .max-w-4xl.mx-auto
        h1.text-center.pb-12 Precios
        .col-2(v-if="productsType == 'subscription'")
          .flex.gap-8.flex-grow
            CardSubscription(:item="defaultProduct")
          paragraphCard.text-gray-500.pt-12.font-medium (*) Transcurrida la prueba y no habiéndose cancelado la suscripción, se renovará automáticamente al plan mensual.
        .col-2(v-if="productsType == 'purchase'")
          .flex.gap-8.flex-grow.justify-center
            CardPurchase(
              v-for="item in listProducts",
              :item="item",
              :key="item._id"
            )
</template>

<script>
import CardComponent from "./components/card.vue";
import paragraphCard from "./components/card-p.vue";
import buttonCard from "./components/card-button.vue";
import listComponent from "./components/list.vue";

import CardPurchase from "./card-purchase.vue";
import CardSubscription from "./card-subscription.vue";

export default {
  name: "prices-component",
  components: {
    CardComponent,
    paragraphCard,
    buttonCard,
    listComponent,
    CardPurchase,
    CardSubscription,
  },
  data: () => ({
    listText: [
      "Descarga de forma ilimitada tu CV desde cualquier lugar.",
      "Crea y modifica tu CV siempre que lo necesites.",
      "Utiliza plantillas completamente personalizables y únicas.",
      "Comparte con un click tu CV.",
      "Sín compromiso, cancela la cuenta cuando quieras.",
    ],
  }),
  computed: {
    defaultProduct() {
      return this.$store.getters["ProductsStore/getproductDefault"];
    },
    productsType() {
      return this.$store.getters["ProductsStore/getproductDefault"]
        .product_type;
    },
    listProducts() {
      const products = this.$store.getters["ProductsStore/getListProducts"];
      return products.sort(
        (productActual, productBefore) =>
          productActual.downloads_sum - productBefore.downloads_sum
      );
    },
  },
  methods: {
    parseCashPurchases() {},
  },
};
</script>

<style lang="scss" scoped>
.prices-component {
  @apply flex gap-4 py-24 flex-col items-center;

  .col-1 {
    @apply flex flex-col justify-center flex-grow;
  }
  .col-2 {
    @apply flex justify-center flex-grow;
  }
}

h1 {
  @apply font-bold text-3xl pr-4 text-gray-700;
}
</style>
